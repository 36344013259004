export interface TsAppVersion {
    version: string;
    name: string;
    description?: string;
    versionLong?: string;
    versionDate: string;
    gitCommitHash?: string;
    gitCommitDate?: string;
    gitTag?: string;
};
export const versions: TsAppVersion = {
    version: '2.2.14',
    name: 'fpar',
    versionDate: '2025-01-23T16:35:46.300Z',
};
export default versions;
